import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/107/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup>
                    <sup>&nbsp; </sup>Skin Detox Hydratační krém 2v1
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-krem-2v1-50-ml2.jpg"
                      alt="NTG SkinDetox krem 2v1 50 ml2"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup>
                      <sup>&nbsp; </sup>Skin Detox Hydratační krém 2v1
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p className="TableParagraph">NOVINKA</p>
                      <p className="TableParagraph">
                        Znečištěné ovzduší negativně působí na pleť nejen na
                        povrchu, ale i do hloubky. Drobné částice nečistot
                        pronikají do hlubších vrstev pokožky a způsobují zánět,
                        dehydrataci, ale i další poškození vedoucí až k
                        předčasnému stárnutí pleti.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Skin Detox Hydratační krém 2v1 nejen ochrání Vaši pleť
                        před negativními vlivy prostředí, ale zároveň ji bude
                        regenerovat.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Lehký sametový krém obsahuje jedinečnou kombinaci:
                      </p>
                      <ul>
                        <li>
                          Přísad chránících před škodlivinami a silného
                          antioxidantu, které vytvářejí pro Vaši pleť ochranný
                          štít proti vnějšímu znečištění
                        </li>
                        <li>
                          Peptidů, které pleť regenerují a uchovávají její
                          mladistvý vzhled
                        </li>
                      </ul>
                      <p className="TableParagraph">&nbsp;</p>
                      <p>
                        Pleť je po použití chráněná, regenerovaná a přirozeně
                        zářivá.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Nanášejte každé ráno a večer krouživými pohyby na dokonale
                      vyčištěný obličej a krk.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>50 ml</dd>
                  </dl>
                </div>
                <div id="fb_58"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/98/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-HydroBoost-maska-na-noc-50ml-500x.png"
                      alt="NEUTROGENA HydroBoost maska na noc 50ml 500x"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Hydratační noční maska
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/99/"
                  >
                    <img
                      src="/assets/Uploads/neutrogena-serum.png"
                      alt="neutrogena serum"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Intenzivní Hydratační Sérum
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/100/"
                  >
                    <img
                      src="/assets/Uploads/NTG-HydroBoost-TMW-400mlv.jpg"
                      alt="NTG HydroBoost TMW 400mlv"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Micelární voda 3v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/102/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-WashMask-150ml-bez-stinu.jpg"
                      alt="NTG SkinDetox WashMask 150ml bez stinu"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>
                        <sup>&nbsp; </sup>Skin Detox 2v1 Čistící emulze a maska
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/103/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-TMW-400ml-002f.jpg"
                      alt="NTG SkinDetox TMW 400ml 002f"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Micelární voda 3v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
